<template>
  <nav class="bg-white border-b border-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center py-4">
        <!-- 左侧菜单 -->
        <div class="flex items-center">
          <div class="shrink-0">
            <a href="/">
              <img class="h-10 w-auto" src="/logo.png" alt="Logo">
            </a>
          </div>
          <!-- Desktop Menu -->
          <div class="hidden sm:flex sm:ml-10 space-x-8">
            <div v-for="menu in menus" :key="menu.title" class="relative group">
              <router-link
                v-if="!menu.children"
                :to="menu.link"
                class="nav-link"
              >
                {{ menu.title }}
              </router-link>
              <div v-else>
                <span class="nav-link cursor-pointer">
                  {{ menu.title }}
                </span>
                <!-- 二级菜单 (桌面端) -->
                <div class="hidden group-hover:flex absolute bg-white shadow-lg rounded-lg py-2 z-50 mt-2">
                  <router-link
                    v-for="child in menu.children"
                    :key="child.title"
                    :to="child.link"
                    class="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    {{ child.title }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Menu Button -->
        <div class="sm:hidden">
          <button @click="open = !open" :aria-expanded="open.toString()" class="text-gray-500 hover:text-gray-700 focus:outline-none">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu -->
      <div v-if="open" class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <div v-for="menu in menus" :key="menu.title">
            <div>
              <router-link
                v-if="!menu.children"
                :to="menu.link"
                class="block pl-3 pr-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300"
              >
                {{ menu.title }}
              </router-link>
              <div v-else>
                <div
                  @click="toggleMenu(menu.title)"
                  class="flex justify-between items-center px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50"
                >
                  {{ menu.title }}
                  <svg
                    :class="{ 'rotate-180': openMenus.includes(menu.title) }"
                    class="h-4 w-4 transform transition-transform"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                </div>
                <!-- 二级菜单 (移动端) -->
                <div
                  v-if="openMenus.includes(menu.title)"
                  class="pl-6 space-y-1"
                >
                  <router-link
                    v-for="child in menu.children"
                    :key="child.title"
                    :to="child.link"
                    class="block pl-3 pr-4 py-2 text-sm text-gray-500 hover:bg-gray-50"
                  >
                    {{ child.title }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      open: false, // 控制移动端菜单展开
      openMenus: [], // 移动端二级菜单状态
      menus: [
        { title: '首页', link: '/home' },
        { title: '热门股票', link: '/stock-ths-hot' },
        { title: '打板专题', link: '/stock-limit-list' },
        { title: '均线穿越', link: '/stock-moving-average' },
        { title: '股价高低', link: '/stock-days-highest-lowest'},
        { title: '技术指标', link: '/stock-indicators' },
        { title: '我的交易', link: '/trade-records' },
        { title: '我的笔记', link: '/trade-diary' },
        { title: '仓位管理', link: '/user-positions' },
        { title: '交易原则', link: '/trading-principles'},
      ],
    };
  },
  methods: {
    toggleMenu(title) {
      // 切换菜单展开状态
      if (this.openMenus.includes(title)) {
        this.openMenus = this.openMenus.filter((t) => t !== title);
      } else {
        this.openMenus.push(title);
      }
    },
  },
};
</script>
<style scoped>
.nav-link {
  @apply text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out;
}

.router-link-active {
  @apply text-blue-500 border-b-2 border-blue-500;
}

.router-link-exact-active {
  @apply text-blue-600 font-semibold;
}

.group-hover\:flex {
  display: none;
}

.group:hover .group-hover\:flex {
  display: flex;
}
</style>
