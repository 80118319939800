import { createRouter, createWebHistory } from "vue-router";

const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent); // 检测是否为移动端

// 动态加载 desktop 或 mobile 的页面
const loadPage = (page) => {
  const folder = isMobile ? "mobile" : "desktop";
  return () => import(`@/pages/${folder}/${page}.vue`);
};
// 定义路由
const routes = [
  { path: "/", name: "index", component: loadPage("IndexPage"), meta: { title: "欢迎来到 My Trading Strategy" } },
  { path: "/register", name: "register", component: loadPage("Auth/UserRegister"), meta: { title: "注册 - My Trading Strategy" } },
  { path: "/login", name: "login", component: loadPage("Auth/UserLogin"), meta: { title: "登录 - My Trading Strategy" } },
  { path: "/change-password", name: "change-password", component: loadPage("Auth/ChangePassword"), meta: { requiresAuth: true, title: "修改密码 - My Trading Strategy" } },
  { path: "/home", name: "home", component: loadPage("HomePage"), meta: { requiresAuth: true, title: "首页 - My Trading Strategy" } },
  { path: "/stock-limit-list", name: "stock-limit-list", component: loadPage("StockLimitListD"), meta: { requiresAuth: true, title: "打板专题 - My Trading Strategy" } },
  { path: "/stock-moving-average", name: "stock-moving-average", component: loadPage("StockMovingAverage"), meta: { requiresAuth: true, title: "均线穿越 - My Trading Strategy" } },
  { path: "/stock-days-highest-lowest", name: "stock-days-highest-lowest", component: loadPage("StockDaysHighestLowest"), meta: { requiresAuth: true, title: "股价高低 - My Trading Strategy" } },
  { path: "/stock-ths-hot", name: "stock-ths-hot", component: loadPage("StockThsHot"), meta: { requiresAuth: true, title: "热门股票 - My Trading Strategy" } },
  { path: "/stock-indicators", name: "stock-indicators", component: loadPage("StockTechnicalIndicators"), meta: { requiresAuth: true, title: "技术指标 - My Trading Strategy" } },
  { path: "/stock-operator-list", name: "stock-operator-list", component: loadPage("StockOperator/StockOperatorListPage"), meta: { requiresAuth: true, title: "大作手列表 - My Trading Strategy" } },
  { path: "/user-positions", name: "user-positions", component: loadPage("UserPosition"), meta: { requiresAuth: true, title: "仓位管理 - My Trading Strategy" } },
  { path: "/trading-principles", name: "trading-principles", component: loadPage("TradePrinciplesPage"), meta: { requiresAuth: true, title: "交易原则 - My Trading Strategy" } },
  { path: "/trade-records/create", name: "trade-record-create", component: loadPage("TradeRecords/CreateTradeRecord"), meta: { requiresAuth: true, title: "创建交易记录 - My Trading Strategy" } },
  { path: "/trade-records", name: "trade-record-index", component: loadPage("TradeRecords/IndexTradeRecord"), meta: { requiresAuth: true, title: "交易记录列表 - My Trading Strategy" } },
  { path: "/trade-records/:id", name: "trade-record-show", component: loadPage("TradeRecords/ShowTradeRecord"), meta: { requiresAuth: true, title: "交易详情 - My Trading Strategy" } },
  { path: "/trade-diary/create", name: "trade-diary-create", component: loadPage("TradeDiary/CreateTradeDiary"), meta: { requiresAuth: true, title: "创建笔记 - My Trading Strategy" } },
  { path: "/trade-diary", name: "trade-diary-index", component: loadPage("TradeDiary/IndexTradeDiary"), meta: { requiresAuth: true, title: "笔记列表 - My Trading Strategy" } },
  { path: "/trade-diary/:symbol", name: "trade-diary-show", component: loadPage("TradeDiary/ShowTradeDiary"), meta: { requiresAuth: true, title: "笔记详情 - My Trading Strategy" } },
  { path: "/logout", name: "logout", component: loadPage("Auth/UserLogout") },
  { path: "/TestPage", name: "TestPage", component: loadPage("TestPage"), meta: { requiresAuth: true, title: "测试页面 - My Trading Strategy" } },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局导航守卫
router.beforeEach((to, from, next) => {
  // 检查路由是否需要登录
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = !!localStorage.getItem("auth_token"); // 假设使用 localStorage 存储 token

    if (!isAuthenticated) {
      // 如果未登录，跳转到登录页面
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
